import React from 'react'
import Sidebar from './Sidebar'
import Topbar from './Topbar'

const Homepage = () => {
  return (
    <div>
      <Topbar/>
    </div>
  )
}

export default Homepage
